import "core-js/modules/es.function.name.js";
import BusinessIdCopy from '@/components/business/BusinessIdCopy.vue';
import BusinessInfoEdit from '@/components/business/BusinessInfoEdit.vue';
import SupportContactEdit from '@/components/business/SupportContactEdit.vue';
import BusinessProfileNav from '@/components/in-page-nav/BusinessProfileNav.vue';
import LayoutAdmin from '@/components/layouts/LayoutAdmin.vue';
import VersionIndicator from '@/components/VersionIndicator.vue';
import useUserPlanInfo from '@/composables/useUserPlanInfo';
import { useBusinessStore } from '@/stores/business';
export default defineComponent({
  components: {
    LayoutAdmin: LayoutAdmin,
    BusinessIdCopy: BusinessIdCopy,
    BusinessProfileNav: BusinessProfileNav,
    BusinessInfoEdit: BusinessInfoEdit,
    VersionIndicator: VersionIndicator,
    SupportContactEdit: SupportContactEdit
  },
  setup: function setup() {
    var businessStore = useBusinessStore();
    var _useUserPlanInfo = useUserPlanInfo(),
      activePricePlanId = _useUserPlanInfo.activePricePlanId;
    var companyName = computed(function () {
      return businessStore.name;
    });
    var showSupportContactForm = computed(function () {
      return activePricePlanId.value === 'ent_v2' || activePricePlanId.value === 'TRIAL_V1' || activePricePlanId.value === 'TEAM_V1' || activePricePlanId.value === 'PRO_V1' || activePricePlanId.value === 'SCALE';
    });
    return {
      companyName: companyName,
      showSupportContactForm: showSupportContactForm
    };
  },
  head: function head() {
    return {
      title: 'Business - Profile'
    };
  }
});